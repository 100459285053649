import * as React from "react";
import { Component } from "react";
import { Col, Container, Row } from "react-bootstrap";
import ArticleLayout from "../articles/article-layout";
import References from "../global/references";
import copyList from "./copyList.js";
import WWLFImage from "../../images/WWLFImage.png";
import WWLFImageMobile from "../../images/WWLFImage-mobile.png";
import BackToTop from "../global/BackToTop";

export default class Content extends Component {
  render() {
    return (
      <div className={`${this.props.show ? "d-block" : "d-none"}`}>
        <Row
          xs={11}
          lg={10}
          className={"hero " + this.props.page + " px-0"}
          role="img"
          aria-label=""
        ></Row>
        <Container className="">
          <Row>
            <Col xs={10} className="offset-1">
              <div
                className={
                  "hero-callout muted-yellow-dark hero-callout-" +
                  this.props.page
                }
              >
                <h1
                  className={
                    this.props.noUpper ? "sub-title no-upper" : "sub-title"
                  }
                >
                  {this.props.subheading}
                </h1>
                <h1
                  className={
                    this.props.noUpperTitle ? "title no-upper" : "title"
                  }
                >
                  GILEAD HIV & <br />
                  USCHA 2022
                </h1>
              </div>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={10} className="mx-auto">
              <p className="mb-5">
                It has been more than two years since the HIV community has been
                together for the US Conference on HIV/AIDS (USCHA). This year,
                we are back in person, in beautiful Puerto Rico, shining a
                much-needed light on the impact of HIV on the Puerto Rican and
                Hispanic/Latino communities. Gilead HIV is proud to be the
                presenting sponsor of this momentous conference, taking place in
                San Juan, PR, from October 8 to 11. We look forward to coming
                together as a community, celebrating progress in HIV care, and
                convening around driving urgency in HIV amid competing health
                priorities, such as the COVID-19 pandemic and now monkeypox.
                <sup>1-3</sup>
              </p>
              <figure className="uscha-conference-image mb-5">
                <img src={WWLFImage} />
                <img src={WWLFImageMobile} />
              </figure>
              <p className="mb-5">
                Throughout the conference, Gilead Sciences will be hosting
                numerous programs, events, activations, and an educational
                booth. We hope you will stop by to connect with us.
              </p>
            </Col>
          </Row>
        </Container>
        <Container>
          <Row>
            <Col xs={10} className="mx-auto">
              <p>
                <Container>
                  <Row>
                    <Col className="uscha-conference-title">
                      <h5>CONNECT WITH GILEAD HIV DURING USCHA AT:</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="uscha-conference-content">
                      <div>
                        <h5 className="title">
                          Celebrating the Art of La Brega to Help End the HIV
                          Epidemic
                        </h5>
                        <h5 className="subtitle-flex">
                          <span>Plenary Session</span>
                          <span>Sunday, October 9</span>
                          <span>
                            11:30 <small>AM</small> – 1:30 <small>PM</small> ET
                          </span>
                        </h5>
                        <p>
                          “La Brega” loosely translates to “the hustle” or “the
                          struggle.”<sup>4,5</sup> It speaks to the tenacity and
                          ingenuity that the Puerto Rican people demonstrate
                          when responding to life’s challenges. La Brega
                          expresses a sense of solidarity and pride in the way
                          Puerto Ricans face and overcome adversity.<sup>5</sup>{" "}
                          While there have been significant obstacles since the
                          beginning of the HIV epidemic, the community continues
                          to persevere and lead the way toward a better future.
                          <sup>6,7</sup> Even with the unprecedented challenges
                          brought about by the COVID-19 pandemic, the HIV
                          community has responded with characteristic
                          determination and innovation.<sup>8</sup> Join us for
                          a plenary luncheon celebrating La Brega and the
                          unwavering resolve of the HIV community to help bring
                          about the end of the HIV epidemic.
                        </p>
                      </div>
                      <div>
                        <h5 className="title">
                          <i>Blind Angels</i> Activation
                        </h5>
                        <h5 className="subtitle">
                          Floor 3 Near Plenary Ballroom
                        </h5>
                        <p className="word-wrap">
                          We are excited to showcase{" "}
                          <i>
                            Blind Angels: A Series on HIV in the American South
                          </i>{" "}
                          to USCHA. <i>Blind Angels</i> is a six-part docuseries
                          produced with Courageous Studios, a brand studio of
                          HLN and CNN. Each of the episodes features grassroots
                          advocates working to bring HIV awareness, education,
                          prevention, and care to their communities. Check out
                          the <i>Blind Angels</i> activation on the third floor
                          to explore the series and watch episodes celebrating
                          advocates helping end the HIV epidemic in their
                          communities. You can also view the series online
                          at&nbsp;
                          <a href="https://www.gileadhiv.com/community-involvement/blind-angels/">
                            https://www.gileadhiv.com/community-involvement/blind-angels/
                          </a>
                          .
                        </p>
                      </div>
                      <div>
                        <h5 className="title">Workshops</h5>
                        <p>
                          Gilead is excited to host several workshops throughout
                          USCHA, focusing on a range of topics related to the
                          HIV epidemic today:
                        </p>
                        <ul>
                          <li>
                            <h5 className="subtitle-inline">
                              <i>
                                Using Data for Action: Achieving Equity in PrEP
                                (pre-exposure prophylaxis) Use
                              </i>
                            </h5>{" "}
                            (hosted by AIDSVu, a partnership between Rollins
                            School of Public Health at Emory University and
                            Gilead Sciences)
                            <h5 className="subtitle-flex">
                              <span>Saturday, October 8</span>
                              <span>
                                1:45 <small>PM</small> – 3:45 <small>PM</small>{" "}
                                ET
                              </span>
                            </h5>
                          </li>
                          <li>
                            <h5 className="subtitle-inline">
                              <i>
                                We’ve Been Here Before! Applying Lessons Learned
                                to Address the Southern Syndemic
                              </i>
                            </h5>{" "}
                            (hosted by COMPASS (COMmitment to Partnership in
                            Addressing HIV/AIDS in Southern States) Initiative
                            <sup>®</sup> Coordinating Centers)
                            <h5 className="subtitle-flex">
                              <span>Sunday, October 9</span>
                              <span>
                                4:00 <small>PM</small> – 6:00 <small>PM</small>{" "}
                                ET
                              </span>
                            </h5>
                          </li>
                          <li>
                            <h5 className="subtitle-inline">
                              <i>
                                Making Room for Our Multiple Needs: Elevating
                                the Importance of HIV Care Amid Competing Public
                                Health Crises
                              </i>
                            </h5>{" "}
                            (hosted by Gilead Community Leaders)
                            <h5 className="subtitle-flex">
                              <span>Monday, October 10</span>
                              <span>
                                2:00 <small>PM</small> – 4:00 <small>PM</small>{" "}
                                ET
                              </span>
                            </h5>
                          </li>
                          {/* <li>
                            <h5 className="subtitle-inline">
                              <i>
                                Healthysexual
                                <sup>®</sup>
                              </i>
                            </h5>{" "}
                            (hosted by Gilead Community Leaders)
                            <h5 className="subtitle-flex">
                              <span>Sunday, October 9</span>
                              <span>
                                4:15 <small>PM</small> – 6:15 <small>PM</small>{" "}
                                ET
                              </span>
                            </h5>
                          </li> */}
                        </ul>
                      </div>
                      <div>
                        <h5 className="title display-inline">
                          Faith Service Pop-up
                        </h5>{" "}
                        <p className="display-inline">
                          (partnership with Wake Forest Coordinating Center and
                          Balm in Gilead that highlights the role that faith can
                          play in helping end the HIV epidemic)
                        </p>
                        <h5 className="subtitle-flex">
                          <span>Sunday, October 9</span>
                          <span>
                            7:00 <small>PM</small> – 8:00 <small>PM</small> ET
                          </span>
                        </h5>
                      </div>
                      <div>
                        <h5 className="title">Exhibit Booth</h5>
                        <p>
                          Visit booth #504 to learn about Gilead HIV’s
                          commitment to helping end the HIV epidemic—take a
                          photo, share what you live for, and connect with a
                          Gilead Community Leader.{" "}
                        </p>
                      </div>
                      <div>
                        <h5 className="title">GileadHIV.com</h5>
                        <p>
                          Visit our website to explore our latest resources on
                          helping end the epidemic and our ongoing commitment to
                          those living with and at risk for HIV.
                        </p>
                      </div>
                      <div>
                        <h5 className="title">Social Channels</h5>
                        <p>
                          Connect with us on{" "}
                          <a href="https://www.instagram.com/gileadhivus/">
                            <h5 className="subtitle-inline">Instagram</h5>
                          </a>{" "}
                          and{" "}
                          <a href="https://www.facebook.com/GileadHIVUS/">
                            <h5 className="subtitle-inline">Facebook</h5>
                          </a>{" "}
                          to stay up to date on initiatives, awareness days, and
                          education about the HIV community.
                        </p>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </p>
            </Col>
          </Row>
        </Container>
        <BackToTop />
        <References page={this.props.page} />
      </div>
    );
  }
}

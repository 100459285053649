import * as React from "react";
import { Component } from "react";
import Layout from "../../components/global/layout.js";
import Content from "../../components/USCHA-conference-2022/content.js";

class USCHAConference extends Component {
  render() {
    return (
      <>
        <Layout section="deeper-dive" page="USCHA-conference-2022">
          <Content section="deeper-dive" page="USCHA-conference-2022" />
        </Layout>
      </>
    );
  }
}

export default USCHAConference;
